import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import AniCar from '../components/Animations/AniCar'
import AniWhiteWatch from '../components/Animations/AniWhiteWatch'
import AniWhiteDrone from '../components/Animations/AniWhiteDrone'
const UseCases = () => ( <Layout>
  <div>
  <Helmet
  title={'temtum Use Cases | Crypto Casino | Cryptocurrency Payment Gateway'}
  meta={[
      {
        name: 'description',
        content: 'temtum has multiple use cases, where high transaction throughput and feeless transactions benefit most, crypto casinos & e-commerce.'
      }
    ]}
    />
    <div className="hd-space"/>
    <section className="text-white skipScroll bg-blue-3 bg-use-world">
      <div className="wow video-overlay"/>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="display-1  pb-4">Discover real world use cases</h2>
            <p className="lead">
              Build scalable payment infrastructures on temtum. Learn how we've used temtum in the real world.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="text-whiteX skipScroll bg-blue-3X">
      <div className="container">
        <div className="row">
          <div className="col-md-9 mx-auto text-center">
            <h2 className="display-4 mb-3">
              Durability, Scarcity, Divisibility, Convenience, Ease of Adoption
            </h2>
            temtum is an efficient ultra-fast international payment coin and alternative to fiat money, that can be used on a stand-alone basis, or can be integrated into payment systems worldwide, with the capacity to serve as a payment rail for consumers and businesses alike.
          </div>
        </div>
      </div>
    </section>
    <section className="skipScroll py-4 bg-blue-3 border-top-blue">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <a className="nav-link" href="#mobile">
                  Mobile Payments
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="#sms">
                  SMS Payments
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#ecommerce">
                  E-Commerce
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#gambling">
                  Gaming Industry
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-blue-3 bg-use-mobile" id="mobile">
      <div className="darkleft video-overlay"/>
      <div className="wow3 video-overlay"/>
      <div className="mt-5 text-white">
        <div className="">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="800">
              <div className="col col-12 col-sm-12 col-md-6">
                <h2 className="display-3 font-weight-normal pb-4">
                  Mobile
                </h2>
                <p className="">
                  temtum’s ambition to be the first cryptocurrency to achieve mass global adoption means that we have ensured that the network can fully integrate with standard real world payment systems. We have established solutions for point of sale (POS) mobile payments so that there will be a seamless process for purchases. For the user they will not differ in experience from regular currency payments.
                </p>
                <p className="">
                  Any user with a temtum wallet will be able to make purchases using conventional payment networks such as Visa for instance, with total transparency to the vendor who will see only a fiat money transaction. Yet all the advantages of temtum – for example immediate international transfer of value – will still be available.
                </p>
                <Link to="/whitepaper" className="btn btn-outline-light">
                  Read more in out White Paper
                </Link>
              </div>
              <div className="col col-12 col-sm-12 col-md-6"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-blue-2 bg-use-sms" id="sms">
      <div className="blue video-overlay"/>
      <div className="wow5 video-overlay"/>
      <div className="mt-5 text-white">
        <div className="">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="800">
              <div className="col col-12 col-sm-12 col-md-6">
                <h2 className="display-3 font-weight-normal pb-4">
                  SMS
                </h2>
                <p className="">
                  The SMS system does not require users to download an app, and there are no requirements for accounts or passwords. If the user has a device that can send an SMS text message, then they are able to use temtum. Similarly, the recipient needs neither an app nor a wallet to receive funds. This makes it suitable for non-smartphones , and is intended largely for use in countries with a high penetration rate of such devices, the use of which is driven by unpredictable electrical power and intermittent or less widely available high speed internet.
                </p>
                <p className="">
                  The SMS allows for instant transfers. Transactions are immediately settled directly on the Temporal Blockchain and funds are never held by a third party.
                </p>
                <Link to="/whitepaper" className="btn btn-outline-light">
                  Read more in out White Paper
                </Link>
              </div>
              <div className="col col-12 col-sm-12 col-md-6"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-blue-3 bg-use-ecommerce" id="ecommerce">
      <div className="blue video-overlay"/>
      <div className="wow3 video-overlay"/>
      <div className="mt-5 text-white">
        <div className="">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="800">
              <div className="col col-12 col-sm-12 col-md-6">
                <h2 className="display-3 font-weight-normal pb-4">
                  E-commerce payment gateway
                </h2>
                <p className="">
                  There are currently two methods of accepting temtum for the e-commerce site:
                </p>
                <p className="lead">
                  <strong>Full temtum e-commerce</strong>
                  – An online store which accepts temtum for goods – the store has their own wallet for payment
                </p>
                <p className="">
                  <strong>
                    Online stores Accepting temtum</strong>
                  - Works like a regular store – pay with temtum button – but this is automatically converted to fiat via an exchange, so the store never holds or receives temtum only fiat
                </p>
                <p className="">
                  To ensure adoption of TEM as a viable e-commerce payment solution, we’ve developed a Shopify payment plugin, along with a ‘temtum Pay’ process, featuring a quick buy button, much like those from Amazon & PayPal. This will aid in the goal of mass adoption of the currency – which can be deployed day one since we have already developed these features.
                </p>
                <p className="">
                  Cryptocurrencies hold the key to significant savings for merchants that require greater adoption of payment coins to be realised in full. Whilst payment gateways sit between consumer and merchant, transaction costs will always exist.
                </p>
                <Link to="/whitepaper" className="btn btn-outline-light">
                  Read more in out White Paper
                </Link>
              </div>
              <div className="col col-12 col-sm-12 col-md-6"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-blue-3 bg-use-gambling" id="gambling">
      <div className="red video-overlay"/>
      <div className="wow3 video-overlay"/>
      <div className="mt-5 text-white">
        <div className="">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="800">
              <div className="col col-12 col-sm-12 col-md-6">
                <h2 className="display-3 font-weight-normal pb-4">
                  The Gaming Industry
                </h2>
                <p className="">
                  There are two very different ways in which temtum can improve payments in this industry. The first we call the ‘on-ramp’ model – this allows TEM to be used as a stake in a game, either through a traditional deposit of funds or with our temtum ‘Pay as you Play’ model. The second is a full online gaming application running on the temtum network, and is not described here or within this paper, but has already been demonstrated in our gaming demo. This option may form part of our future road map as integrations are demonstrated alongside industry partners – the benefit to the industry is that this technology offers a full audit on the blockchain, so players can see that each game is ‘provably fair’ and as described by the gaming brand.</p>
                <p className="">
                  temtum benefits Players where they can directly use crypto (TEM) as a stake with immediate transactions and no fees. For the casino/gaming app, unlike waiting on average 45 – 60 mins for Bitcoin to confirm a transaction, potentially losing the player and/or valuable gaming time, they will have the funds confirmed in their account within 12 seconds, retaining player engagement.
                </p>
                <Link to="/whitepaper" className="btn btn-outline-light">
                  Read more in out White Paper
                </Link>
              </div>
              <div className="col col-12 col-sm-12 col-md-6"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="display-3 font-weight-normal pb-4 text-center">
              Our Integrations
            </h2>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="">
                    <div className="mb-1">
                      <ul className="nav nav-tabs integrations text-center rowX">
                        <li className="nav-item col-12 col-md-4 col-lg-4">
                          <a className="nav-link active" data-toggle="tab" href="#tab-bmw">
                            <AniCar/>
                            <h3 className="display-4 font-weight-bolder mt-4">BMW i8</h3>
                            <i className="fas fa-long-arrow-alt-right"></i>
                          </a>
                        </li>
                        <li className="nav-item col-12 col-md-4 col-lg-4">
                          <a className="nav-link" data-toggle="tab" href="#tab-watch">
                            <AniWhiteWatch/>
                            <h3 className="display-4 font-weight-bolder mt-4">Smart Watch</h3>
                            <i className="fas fa-long-arrow-alt-right"></i>
                          </a>
                        </li>
                        <li className="nav-item col-12 col-md-4 col-lg-4">
                          <a className="nav-link" data-toggle="tab" href="#tab-drone">
                            <AniWhiteDrone/>
                            <h3 className="display-4 font-weight-bolder mt-4">Drone</h3>
                            <i className="fas fa-long-arrow-alt-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content dark text-white card-body box">
                      <div id="tab-bmw" className="tab-pane active">
                        <h3 className="display-4 font-weight-normal pb-4">
                          BMW i8
                        </h3>
                        <p className="">To demonstrate the technical capabilities of the network, and how few resources a node is required to have in order to participate in the blockchain, we deployed custom code on the head unit (HU) of a BMW i8. This allowed the car to function as a fully working node, capable of confirming transactions as the car was driven. The loading of the code into the car did not have any any impact on its functionality and was able to be run in the background.
                        </p>
                        <p className="">While the computing power is significantly less than traditional computers, the i8 was able to consistently demonstrate the ability to confirm 500 TPS.
                        </p>
                        <p className="">We believe this is a world first where a car is able to act as a full node without the requirement of additional hardware.
                        </p>
                      </div>
                      <div id="tab-watch" className="tab-pane">
                        <h3 className="display-4 font-weight-normal pb-4">
                          Fitbit Ionic smartwatch
                        </h3>
                        <p className="">We developed an application to load on the Fitbit Ionic smartwatch. The purpose of this application is to notify a user when they have received temtum from other users. This application will be developed further to allow for transactions to be sent from the smart watch.</p>
                      </div>
                      <div id="tab-drone" className="tab-pane">
                        <h3 className="display-4 font-weight-normal pb-4">
                          Drone
                        </h3>
                        <p className="">
                          Much like we did with the BMW i8, we conducted an experiment on whether a drone would be able to confirm transactions while in flight. Unlike the BMW it was not a full node, but it did use the CPU resources to confirm transactions sent to it from a ground station.
                        </p>
                        <p className=" ">
                              Due to the much lower resources of the drone, a full node was not able to run on the drone, but the drone was still able to average 270 TPS
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className=" container"=" container""="container""">
                          <div className="row">
                            <div className="col-lg-4 mx-auto">
                              <div className="card box border bg-platform-mobileX">
                                <AniCar/>
                                <h3 className="display-4 font-weight-bolder mt-4">BMW i8</h3>
                                <p className=" ">Wallets on both Android and Apple.</p>
<a href=" /">Go
                                  <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                              </div>
                            </div>
                            <div className="col-lg-4 mx-auto">
                              <div className="card box border bg-platform-mobileX">
                                <AniWhiteWatch/>
                                <h3 className="display-4 font-weight-bolder mt-4">Smart Watch</h3>
                                <p className=" ">Wallets on both Android and Apple.</p>
<a href=" /">Go
                                  <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                              </div>
                            </div>
                            <div className="col-lg-4 mx-auto">
                              <div className="card box border bg-platform-mobileX">
                                <AniWhiteDrone/>
                                <h3 className="display-4 font-weight-bolder mt-4">Drone</h3>
                                <p className=" ">Wallets on both Android and Apple.</p>
<a href=" /">Go
                                  <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        */ }
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Layout> )
export default UseCases
